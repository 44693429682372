import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {from, Observable} from 'rxjs';
import {LanguageModel} from '../models/language.model';
import {HttpClient} from '@angular/common/http';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends EntityCollectionServiceBase<LanguageModel> {

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    protected serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    super('language', serviceElementsFactory);
  }

  all(): Observable<LanguageModel[]> {
    return this.http.get<any[]>('http://api.lentii.local/language');
  }

  find(languageId: any): Observable<LanguageModel> {
    return this.http.get<LanguageModel>('http://api.lentii.local/language/' + languageId);
  }

  // create(data: LanguageModel): Observable<LanguageModel> {
  //   return this.http.post<any>('http://api.lentii.local/language', data);
  //   // return from(this.firestore.collection('languages').doc(this.firestore.createId()).set(data));
  // }

  // update(id: any, data: LanguageModel): Observable<any> {
  //   return this.http.post<any>('http://api.lentii.local/language/' + id, data);
  //   // return from(this.firestore.collection('languages').doc(id).set(data));
  // }

  // delete(id: any): Observable<any> {
  //   return from(this.firestore.collection('languages').doc(id).delete());
  // }
}
