import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LanguageKeyModel} from '../models/language-key.model';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';
import {LanguageModel} from '../models/language.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageKeyService extends EntityCollectionServiceBase<LanguageKeyModel> {

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    protected serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    super('languageKey', serviceElementsFactory);
  }

  // all(): Observable<LanguageKeyModel[]> {
  //   return this.http.get<LanguageKeyModel[]>('http://api.lentii.local/language-key');
  // }

  // create(data: LanguageKeyModel): Observable<any> {
  //   return this.http.post<any>('http://api.lentii.local/language-key', data);
  // }

  // update(id, data: LanguageKeyModel): Observable<any> {
  //   return this.http.post<any>('http://api.lentii.local/language-key/' + id, data);
  // }
}
