import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, combineLatest, Observable, throwError} from 'rxjs';
import {filter, switchMap, tap} from 'rxjs/operators';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user = new BehaviorSubject(null);

  user$ = this.user.asObservable();

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
  ) {
  }

  protected readonly clientData = {
    client_id: environment.auth.clientId,
    client_secret: environment.auth.clientSecret,
    grant_type: environment.auth.grantType,
  };

  login(data): Observable<any> {
    return this.http.post<any>(environment.apiDomain + '/oauth/v2/token', {
      ...data,
      ...this.clientData,
      user_agent: window.navigator.userAgent,
    }).pipe(
      switchMap(accessData => combineLatest(
        [
          this.tokenService.setAccessToken(accessData.access_token),
          this.tokenService.setRefreshToken(accessData.refresh_token)
        ]
      )),
      switchMap(() => this.getUser()),
      tap((user: any) => {
        this.setUser(user);
      })
    );
  }

  logout(): Observable<any> {
    return this.tokenService.clear().pipe(
      tap(() => {
        this.user.next(null);
      })
    );
  }

  refreshToken(): Observable<any> {
    return this.tokenService.getRefreshToken().pipe(
      switchMap((refreshToken: string) => {
        if (refreshToken) {
          const data = {...this.clientData, ...{grant_type: 'refresh_token', refresh_token: refreshToken}};
          return this.http.post(environment.apiDomain + '/oauth/v2/token', data);
        }

        return throwError('Refresh token does not exist');
      }),
      switchMap((accessData: any) => combineLatest(
        [
          this.tokenService.setAccessToken(accessData.access_token),
          this.tokenService.setRefreshToken(accessData.refresh_token)
        ]
      )),
    );
  }

  getUser(): Observable<any> {
    return this.http.get(environment.apiDomain + '/users/info');
  }

  setUser(user: any): void {
    this.user.next(user);
  }

  syncUser(): Observable<any> {
    return this.tokenService.getAccessToken().pipe(
      filter(token => !!token),
      switchMap(() => this.getUser().pipe(
        tap(user => this.user.next(user))
      ))
    );
  }
}
